import { NgModule } from '@angular/core';

// primeng
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { TreeModule} from 'primeng/tree';
import { FileUploadModule } from 'primeng/fileupload';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  declarations: [
    // Componentes, pipes, diretivas compartilhadas
  ],
  imports: [
    TableModule,
    TooltipModule,
    BadgeModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    StepsModule,
    ToastModule,
    TreeModule,
    FileUploadModule,
    SidebarModule,
    SplitButtonModule,
    InputNumberModule
  ],
  exports: [
    TableModule,
    TooltipModule,
    BadgeModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    StepsModule,
    ToastModule,
    TreeModule,
    FileUploadModule,
    SidebarModule,
    SplitButtonModule,
    InputNumberModule
  ]
})
export class PrimengSharedModule {}
