import { Injectable } from '@angular/core'
//import toastr from 'toastr'
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms'
import { AutenticacaoService } from './autenticacao.service'
import { ToastrService } from 'ngx-toastr'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class MensagemService {
  private tituloSucesso = 'Sucesso'
  private tituloInformacao = 'Informação'
  private tituloAviso = 'Aviso'
  private tituloErro = 'Erro'
  private mensagemErro = 'Aconteceu um erro inesperado. Contate o suporte ou tente novamente mais tarde!'
  private mensagemErroFormGroup = 'Favor preencher os campos corretamente!'
  private mensagemErroFormGroupValidacao = 'Favor preencher os seguintes campos corretamente: '


  constructor(private autenticacaoService: AutenticacaoService, private toastr: ToastrService) { }

  public showErroApi(httpErrorResponse: HttpErrorResponse, form: UntypedFormGroup = null) {
    try {
      // estava vindo como string do back não reconheçendo como json.
      // parece que as vezes a resposta do back em umas situações é string, em outras json ?
      let error = httpErrorResponse.error;
      if (typeof error === 'string') {
        try {
          error = JSON.parse(error);
        } catch (e) {
          console.error('Erro ao parsear JSON:', e);
        }
      }

      if (httpErrorResponse.status === 401) {
        this.autenticacaoService.deslogar();
        return;
      }

      const mensagensSemControl: string[] = [];

      if (error?.invalido === true) {
        const notifications = error.notifications || [];

        if (notifications.length > 0) {
          notifications.forEach((notificacao: any) => {
            const key = this.normalizarKey(notificacao.key); // transforma para camelCase do Angular

            const control = form?.get(key);

            if (control && !control.disabled) {
              control.setErrors({ errorMessage: notificacao.message });
            } else {
              mensagensSemControl.push(notificacao.message);
            }
          });

          if (form) {
            this.percorrerFormGroup(form); // marca como touched para exibir validação visual
          }

          if (mensagensSemControl.length > 0) {
            this.showAviso(mensagensSemControl.join('\n'));
          }
        } else {
          this.showAviso(this.mensagemErroFormGroup);
        }
      } else if (error?.result) {
        this.showErro(error.result);
      } else if (error?.errors) {
        this.showErro(this.mensagemErro);
      } else if (error) {
        this.showErro(error);
      } else {
        this.showErro(this.mensagemErro);
      }
    } catch { this.showErro(this.mensagemErro) }

  }

  public showSucesso(mensagem: string) {
    this.toastr.success(mensagem, this.tituloSucesso, {progressBar: true})
  }

  public showErro(mensagem: string) {
    this.toastr.error(mensagem, this.tituloErro, {progressBar: true})
  }

  public showAviso(mensagem: string) {
    this.toastr.warning(mensagem, this.tituloAviso, {progressBar: true})
  }

  public showInformacao(mensagem: string) {
    this.toastr.info(mensagem, this.tituloInformacao, {progressBar: true})
  }

  public verificaFormGroup(form: UntypedFormGroup): boolean {
    if (form.invalid) {
      const camposInvalidos = this.obterCamposInvalidos(form);
      this.percorrerFormGroup(form)
      //this.showErro(this.mensagemErroFormGroup)
      this.showErro(this.mensagemErroFormGroupValidacao + camposInvalidos.join(', '));
      return false
    } else
      return true
  }

  private percorrerFormGroup(form: UntypedFormGroup|UntypedFormArray) {
    for (const field in form.controls) {
      if (field !== null) {
        form.controls[field].markAsTouched()
        this.percorrerFormGroup(form.controls[field] as UntypedFormGroup|UntypedFormArray)
      }
    }
  }

  private obterCamposInvalidos(form: UntypedFormGroup): string[] {
    const camposInvalidos: string[] = [];

    Object.keys(form.controls).forEach(key => {
      const control = form.get(key) as AbstractControl;

      if (control.invalid) {
        camposInvalidos.push(key);
      }
    });

    return camposInvalidos;
  }

  private normalizarKey(key: string): string {
    if (!key) return '';
    return key.replace(/(?:^|[.])\S/g, match => match.toLowerCase());
  }

}
