export const environment = {
  production: true,
  criptografiaChave: '_!@#F2nT34aXe5($%)_',
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  apiUrl: 'https://digibyteprod.azurewebsites.net/',
  apiUrlJwtToken: 'digibyteprod.azurewebsites.net',
  apiNfseUrl: 'https://nfedocs.azurewebsites.net/',
  apiNfseUrlJwtToken: 'nfedocs.azurewebsites.net',
  controleUrl: 'https://prodcontroledb.azurewebsites.net/api/',
  stimulsoftChave:
  "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlHEuvvQTNoVVUwXbFaLxMBaq9H+Tlam3AgQPmCdXsUFCZBwk" +
  "iPgkpe9zjlHBcDjaSKRwsQpRslrGyQ/g3O9rmRkDQ7XTQROpHrnBpCHpdiPRBW4hz1+gASjRWztaUod3J+x7EQorBF" +
  "l5Rn7ZypsLUE2mgsG5gm2FBgOiGBfXTFxIjWnoJSnkF6ij99dT/95dKzipXX6zuZ8vYoqjdc41bhULFyQz+5iBLGx5" +
  "ZDu4mitAVizRLRsFsb3S0KLUa1coJ4l+YAkeCkwICoe5soH1hoiAZwL7vnDTltYhqHoANY8RA7TrKX46PRou+xLVuS" +
  "OqyNuDm0FImBWNNfD8+Bf2mHO6c7Lk5hUcWFJag6jrXHVhgWlziLUteBF/EN88PVpPKMj0ByxqUN4wd3ERHTBFSyHp" +
  "jhBaUfocV8/lrqFHdh8J1W23Pk8GW2oXbm8MOsV5KOjhFk7VR1fHUj4c+5HJwgBAqDl9MXGqbSRoXp254/VlTZRrY0" +
  "/3DcuH4LruvwVwAG6QBetvhhwdQqgThE6COsnN+ktKadZMcrvmkCYa6/ng==",

  cnaeIbgeUrl: 'https://servicodados.ibge.gov.br/api/',
  urlConsultaWS: 'https://receitaws.com.br/v1/cnpj/',
  // https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
  // https://www.youtube.com/watch?v=ln3Cn8NZ4wM
  appInsights: {
    instrumentationKey: 'c4f0c262-2175-4704-b87a-65959cc576eb'
  },

}
